<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-star" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Application Details
                    </div>
                    <div class="text-sm text-500">
                      Details of Applicant
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-7 comp-grid">
            <div class="">
              <div>
                <div class="relative-position" style="min-height: 100px">
                  <template v-if="!loading && item">
                    <div class="row q-col-gutter-x-md">
                      <Accordion lazy :multiple="true" :activeIndex="[3]">
                        <AccordionTab header="Application Information">
                          <div class="grid mt-2">
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Application ID:</div>
                                <div class="col font-bold">
                                  {{ item.application_ref }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">
                                  Application Submission Date:
                                </div>
                                <div class="col font-bold">
                                  {{
                                    $utils.humanDatetime(
                                      item.application_submission_date
                                    )
                                  }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Applicant In School:</div>
                                <div class="col font-bold">
                                  {{ item.applicant_in_school }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Is Fee Paying:</div>
                                <div class="col font-bold">
                                  {{ item.is_fee_paying }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Accepted Institution:</div>
                                <div class="col font-bold">
                                  {{ item.applicant_accepted_institution }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Programme Title:</div>
                                <div class="col font-bold">
                                  {{ item.applicant_accepted_programme_title }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Institution Telephone:</div>
                                <div class="col font-bold">
                                  {{
                                    item.applicant_accepted_institution_telephone
                                  }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Selected Fund Name:</div>
                                <div class="col font-bold">
                                  {{ item.applicant_selected_grant_name }}
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Selected Fund Type:</div>
                                <div class="col font-bold">
                                  {{ item.applicant_selected_grant_type }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab class="" header="Bio Information">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Applicant Name:</div>
                                  <div class="col font-bold">
                                    {{ item.applicant_name }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Passport Picture:</div>
                                  <div class="col font-bold">
                                    <image-viewer
                                      image-size="large"
                                      image-preview-size=""
                                      :src="item.passport_picture"
                                      width="50px"
                                      height="50px"
                                      :num-display="1"
                                    >
                                    </image-viewer>
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Date of Birth:</div>
                                  <div class="col font-bold">
                                    {{ item.applicant_dob }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Country of Origin:</div>
                                  <div class="col font-bold">
                                    {{ item.country }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Home Town:</div>
                                  <div class="col font-bold">
                                    {{ item.hometown }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Home Town Region:</div>
                                  <div class="col font-bold">
                                    {{ item.hometown_region }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">
                                    Religious Denomination:
                                  </div>
                                  <div class="col font-bold">
                                    {{ item.religious_denomination }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">
                                    Religious Denomination (Other):
                                  </div>
                                  <div class="col font-bold">
                                    {{ item.religious_denomination_other }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Email Address:</div>
                                  <div class="col font-bold">
                                    {{ item.email_address }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Marital Status:</div>
                                  <div class="col font-bold">
                                    {{ item.marital_status }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Gender:</div>
                                  <div class="col font-bold">
                                    {{ item.gender }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Primary Contact:</div>
                                  <div class="col font-bold">
                                    {{ item.dial_code }}
                                    {{ item.primary_telephone }}
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Address of Applicant:</div>
                                  <div class="col">
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Address line 1:
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.address_line1 }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Address line 2:
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.address_line2 }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">
                                    Guardian / Parent / Next of Kin Information:
                                  </div>
                                  <div class="col">
                                    <div class="col">
                                      <div class="grid align-items-center">
                                        <div class="col text-gray-500 italic">
                                          Guardian's Name
                                        </div>
                                        <div class="col font-bold">
                                          {{ item.guardian_name }}
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="grid align-items-center">
                                        <div class="col text-gray-500 italic">
                                          Guardian's Telephone
                                        </div>
                                        <div class="col font-bold">
                                          {{ item.guardian_telephone }}
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="grid align-items-center">
                                        <div class="col text-gray-500 italic">
                                          Guardian's Occupation
                                        </div>
                                        <div class="col font-bold">
                                          {{ item.guardian_occupation }}
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="grid align-items-center">
                                        <div class="col text-gray-500 italic">
                                          Guardian's Occupation (Other):
                                        </div>
                                        <div class="col font-bold">
                                          {{ item.guardian_occupation_other }}
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="grid align-items-center">
                                        <div class="col text-gray-500 italic">
                                          Guardian's Email
                                        </div>
                                        <div class="col font-bold">
                                          {{ item.guardian_email }}
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="grid align-items-center">
                                        <div class="col text-gray-500 italic">
                                          Guardian's Address Line 1
                                        </div>
                                        <div class="col font-bold">
                                          {{ item.guardian_address1 }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Sports Information</div>
                                  <div class="col">
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Does applicant do any sports?
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.is_athlete }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Last Competition(s)
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.last_competition }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Would applicant like to represent
                                        institution?
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.sports_rep_institution }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">
                                    Disability (Special Needs Information):
                                  </div>
                                  <div class="col">
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Is applicant disabled in anyway?
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.is_disable }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Disability:
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.disability }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Disability (Other):
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.disability_other }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">
                                    Employment Information
                                  </div>
                                  <div class="col">
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Is applicant employed?
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.is_employed }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Nature of Employment
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.employment_nature }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Nature of Employment (Other):
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.employment_nature_other }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Name and Address of Employer:
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.employment_address }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>

                              <div class="col-12 md:col-12">
                                <div class="grid align-items-center">
                                  <div class="col-4">Identification</div>
                                  <div class="col">
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        National Id Type:
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.national_id_type }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        National Id Number:
                                      </div>
                                      <div class="col font-bold">
                                        {{ item.national_id_number }}
                                      </div>
                                    </div>
                                    <hr />
                                    <div class="grid align-items-center">
                                      <div class="col text-gray-500 italic">
                                        Scanned national Id Card
                                      </div>
                                      <div class="col">
                                        <a
                                          style="text-decoration: none"
                                          class="btn font-bold animate__shakeX text-green-500"
                                          target="_blank"
                                          :href="
                                            $utils.setFileUrl(
                                              item.scanned_national_id_card
                                            )
                                          "
                                          >Click here to view uploaded
                                          document</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab header="Family Information">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Father's Information</div>
                                <div class="col">
                                  <div class="grid">
                                    <div class="col text-gray-500 italic">
                                      Father's Name
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.fathers_name }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Father's Living Status
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.fathers_living_status }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Father's Telephone:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.fathers_telephone }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Father's Occupation:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.fathers_occupation }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Father's Occupation (Other):
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.fathers_occupation_other }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Father's Email:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.fathers_email }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>

                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Mother's Information</div>
                                <div class="col">
                                  <div class="grid">
                                    <div class="col text-gray-500 italic">
                                      Mother's Name:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.mothers_name }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Mother's Living Status:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.mothers_living_status }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Mother's Telephone:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.mothers_telephone }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Mother's Occupation:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.mothers_occupation }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Mother's Occupation (Other):
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.mothers_occupation_other }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Mother's Email:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.mothers_email }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>

                        <AccordionTab header="Academic History">
                          <div class="grid">
                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">Shs Information</div>
                                <div class="col">
                                  <div class="grid">
                                    <div class="col text-gray-500 italic">
                                      Did applicant attend senior high school?
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.attended_shs }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Country or Location:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.shs_country }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Last Shs Attended:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.last_shs_attended }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Last Shs Attended (Other):
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.last_shs_other }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Shs Outside Ghana:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.shs_outside_ghana }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      From:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.shs_from_year }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      To:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.shs_to_year }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Shs Course:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.shs_course }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Index Number:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.index_number }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Existing Qualification:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.existing_qualification }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Is applicant awaiting results?
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.is_awaiting_results }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>

                            <div class="col-12 md:col-12">
                              <div class="grid">
                                <div class="col-4">
                                  Tertiary Institution Information
                                </div>
                                <div class="col">
                                  <div class="grid">
                                    <div class="col text-gray-500 italic">
                                      Has applicant attended any tertiary
                                      institution?
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.has_attended_tertiary }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Country or Location:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_country }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Last Tertiary Attended:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.last_tertiary_attended }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Tertiary Name (Outside Ghana):
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_name_outside }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      From:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_from_year }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      To:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_to_year }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Tertiary Programme:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_programme }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Student Id Number:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_student_id }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Reason for leaving Tertiary:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_reason_for_leaving }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Degree Awarded:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_degree_awarded }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Degree Class:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_degree_class }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Certificate Type:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_certificate_type }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Certificate ID:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_certificate_id }}
                                    </div>
                                  </div>
                                  <hr />
                                  <div class="grid align-items-center">
                                    <div class="col text-gray-500 italic">
                                      Certificate Verification Comments:
                                    </div>
                                    <div class="col font-bold">
                                      {{ item.tertiary_certificate_verify_comments }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                            </div>
                            <div
                              class="col-12 md:col-12"
                              v-if="item.exams_sittings"
                            >
                              <div
                                v-for="(definition, index) in JSON.parse(
                                  item.exams_sittings
                                )"
                                :key="index"
                              >
                                <div class="col-12 md:col-12">
                                  <div class="grid">
                                    <div class="col-4 text-purple-500 mb-2">
                                      {{ "Exams Sitting " + (index + 1) }}:
                                    </div>
                                    <div class="col">
                                      <div class="grid">
                                        <div class="col text-gray-500 italic">
                                          Year:
                                        </div>
                                        <div class="col font-bold">
                                          {{ definition.sitting_year }}
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="grid align-items-center">
                                        <div class="col text-gray-500 italic">
                                          Month:
                                        </div>
                                        <div class="col font-bold">
                                          {{ definition.sitting_month }}
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="grid align-items-center">
                                        <div class="col text-gray-500 italic">
                                          Exams Type
                                        </div>
                                        <div class="col font-bold">
                                          {{ definition.exams_type }}
                                        </div>
                                      </div>
                                      <hr />
                                      <div class="grid align-items-center">
                                        <div class="col text-gray-500 italic">
                                          Index Number
                                        </div>
                                        <div class="col font-bold">
                                          {{ definition.index_number }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AccordionTab>
                      </Accordion>
                    </div>
                  </template>
                  <template v-if="loading">
                    <div class="p-3 text-center">
                      <ProgressSpinner style="width: 50px; height: 50px" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { PageMixin } from "../../mixins/page.js";
import { ViewPageMixin } from "../../mixins/viewpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: 'viewApplicantsPage',
  components: {
  },
  mixins: [PageMixin, ViewPageMixin ],
  props: {
    pageName: {
      type : String,
      default : 'applicants',
    },
    idName: {
      type: String,
      default: 'applicant_id',
    },
    routeName : {
      type : String,
      default : 'applicantsview',
    },
    pagePath: {
      type : String,
      default : 'applicants/view',
    },
    apiPath: {
      type : String,
      default : 'applicants/view',
    },
  },
  data() {
          return {
      item: {
        default :{
        },
      },
    }
  },
  computed: {
    pageTitle:{
      get: function () {
        return "Applicants Details"
      }
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["applicants/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("applicants/setCurrentRecord", value);
      },
    },
  },
  meta() {
    return {
      title: this.pageTitle
    }
  },
  methods: {
    ...mapActions("applicants", [ "fetchRecord", "deleteRecord"]),
      getActionMenuModel(data){
        return []
      },
  },
  watch: {
    $route (to, from){
      //only fetch data when navigated to this page
      if(to.name == this.routeName){
        //this.load();
      }
    },
  },
  async mounted() {
  },
  async created() {
  },
};
</script>
<style scoped>
</style>
